class LifeVineCriteria {
    private lv: LifeVine;
    private basePath: string;

    constructor(lv: LifeVine) {
        this.lv = lv;
    }

    public pass(path: string) {
        this.basePath = path + '/criteria';
        return this;
    }

    public get(id?: number, options?: LifeVineCriteriaOptions) {
        options = options || {};
        return this.lv.ajax('GET', this.getPath(id));
    }

    public create(data) {
        return this.lv.ajax('POST', this.getPath(), data);
    }

    public save(id: number, data) {
        return this.lv.ajax('PUT', this.getPath(id), data);
    }

    public remove(id: number) {
        return this.lv.ajax('DELETE', this.getPath(id));
    }

    private getPath(id?: number|null) {
        return id ? `${this.basePath}/${id}` : this.basePath;
    }
}

interface LifeVineCriteriaOptions {
    deleted?: boolean;
}